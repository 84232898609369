import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from '../views/Index/index.vue'
import Service from '../views/Service/index.vue'
import Advantage from '../views/Advantage/index.vue'
import Driedfood from '../views/Driedfood/index.vue'
import romm from '../views/Driedfood/romm/index.vue'
import information from '../views/Driedfood/information/index.vue'
import report from '../views/Driedfood/report/index.vue'
import Cooperation from '../views/Cooperation/index.vue'
import About from '../views/About/index.vue'
import Retrieve from '../views/Retrieve/index.vue'
import retone from '../views/Retrieve/retone/index.vue'
import replace from '../views/Retrieve/replace/index.vue'
import over from '../views/Retrieve/over/index.vue'
import enroll from '../views/enroll'
import Clause from '../views/Clause'
import Privacy from '../views/Privacy'
import driedfoodInfo from '../views/Driedfood/components/DriedfoodInfo/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'index',
        component: Index,
      },
      {
        path: '/service',
        name: 'service',
        component: Service
      },
      {
        path: '/advantage',
        name: 'advantage',
        component: Advantage
      },
      {
        path: '/driedfood',
        name: 'driedfood',
        component: Driedfood,
        // redirect: { name: 'romm' },
        children: [
          {
            path: `/driedfood/romm`,
            name: 'romm',
            component: romm,
          },
          {
            path: `/driedfood/information`,
            name: 'information',
            component: information
          },
          {
            path: `/driedfood/report`,
            name: 'report',
            component: report
          },
          {
            path: `/driedfood/driedfoodInfo`,
            name: 'driedfoodInfo',
            component: driedfoodInfo,
          },
        ]
      },

      {
        path: '/cooperation',
        name: 'cooperation',
        component: Cooperation
      },
      {
        path: '/about',
        name: 'about',
        component: About
      },

    ]
  },
  {
    path: '/retrieve',
    name: 'retrieve',
    // redirect: '/retone',
    component: Retrieve,
    children: [
      {
        path: '/retone',
        name: 'retone',
        component: retone,
      },
      {
        path: '/replace',
        name: 'replace',
        component: replace,
      },
      {
        path: '/over',
        name: 'over',
        component: over,
      },
    ]
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: enroll
  },
  {
    path: '/clause',
    name: 'clause',
    component: Clause
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  }
]

const router = new VueRouter({
  // scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
