<template>
    <div class="enroll">
        <div class="bj">
            <div class="biao">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                    class="regines">
                    <h1>注册</h1>
                    <el-form-item prop="phone" :rules="[
                        { required: true },
                        { type: 'number', message: '手机号必须为数字值' }
                    ]" style="margin-top:40px">
                        <el-input v-model.number="ruleForm.phone" maxlength="11" placeholder="请输入用户手机号码"></el-input>
                    </el-form-item>
                    <el-form-item prop="msg">
                        <el-input v-model.number="ruleForm.msg" placeholder="手机验证码"></el-input>
                        <el-button type="primary" class="prbtn" style="" @click="changeMsg"
                            :disabled="ruleForm.disabled">{{ ruleForm.message }}</el-button>
                    </el-form-item>
                    <el-form-item prop="pass">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"
                            placeholder="请输入设置密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="checkPass">
                        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"
                            placeholder="请输入确定密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="quedin"
                            style="width:356px;height:54px;background:#EA5504;border:none"
                            @click="addUser">确定</el-button>
                    </el-form-item>
                    <label for="" class="bttns">
                        <div class="checkboxs">
                            <van-checkbox v-model="checked"></van-checkbox>
                        </div>
                        <div class="opop">
                            登录即代表同意用户
                            <a href="javascript:;" @click="clause">服务使用政策</a>和
                            <a href="javascript:;" @click="privacy">隐私政策</a>
                        </div>
                    </label>
                    <div class="zi">
                        <van-divider
                            :style="{ color: '#65748D', borderColor: '#8B94C8', fontSize: '16px', width: '50%', margin: '0 auto' }">
                            服务源自于专注
                        </van-divider>
                    </div>
                </el-form>
            </div>
            <div class="tips">
                <span>专业服务源自于专注的态度、不懈的的努力和持之以恒的坚持</span>
            </div>
        </div>
    </div>
</template>
<script>
import { coDe, addUsers } from '@/unitle/api'
import { Toast } from 'vant';
export default {
    data() {
        var telphone = (rule, value, callback) => {
            var pattern = new RegExp("^1[34578][0-9]{9}$", 'i');
            if (!pattern.test(this.ruleForm.phone)) {
                callback(new Error('请输入正确手机号'));
            } else {
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            const min = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,14}$/;
            if (this.timers !== null) clearTimeout(this.timers)
            this.timers = setTimeout(() => {
                if (this.ruleForm.pass === '' || this.ruleForm.pass === '' == null) {
                    callback(new Error('密码不能为空'));
                } else if (this.ruleForm.pass.length < 8 || this.ruleForm.pass.length > 14 || !min.test(this.ruleForm.pass)) {
                    callback(new Error('密码格式8~14位数字与英文组合'));
                } else {
                    callback();
                }
            }, 900)
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            checked: false,
            ruleForm: {
                pass: '',//密码
                checkPass: '',//重复密码
                phone: '',
                msg: '',//验证码
                disabled: false,
                message: '发送验证码',
            },
            token: String,
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                phone: [
                    { validator: telphone, trigger: 'blur' }
                ]
            },
            time: 60,
            Show: false,
            npsicon: "iconfont icon-yanjing",
            npicon: "iconfont icon-yanjing",
            timers: null,
        }
    },
    created() {

    },
    methods: {
        clause() {
            this.$router.push({ name: 'clause' })
        },
        privacy() {
            this.$router.push({ name: 'privacy' })
        },
        //防抖、节流
        changeValue() {
            // 如果timer不为空就表明在持续输入,每次改变都清除定时，这样就可以实现只输入最后一次结果(注意每次间隔如果超过700毫秒就会输出)
            if (this.timers !== null) clearTimeout(this.timers)
            this.timers = setTimeout(() => {
                // console.log(this.msg)
            }, 700)
        },
        //获取验证码
        changeMsg() {
            let params = {
                phone: this.ruleForm.phone
            }
            if (this.ruleForm.phone == '') {
                Toast.fail('请输入手机号');
            } else {
                coDe(params)
                    .then((res) => {
                        // console.log(res);
                        this.ruleForm.disabled = true;
                        this.ruleForm.message = this.time + 's后重新获取';
                        var timer = setInterval(() => {
                            this.time--;
                            this.time = this.time >= 0 ? this.time : 0;
                            this.ruleForm.message = this.time + 's后重新获取';
                            if (this.time == 0) {
                                this.ruleForm.message = '重新获取';
                                this.ruleForm.disabled = false;
                                clearInterval(timer);
                                this.time = 60;
                            }
                        }, 1000);
                    })
            }
        },
        addUser() {
            let formData = {
                phone: this.ruleForm.phone,
                password: this.ruleForm.pass,
                code: this.ruleForm.msg
            };
            if (this.checked == true) {
                this.$refs['ruleForm'].validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        addUsers(formData)
                            .then(res => {
                                console.dir(res.data)
                                if (res.data.error) {
                                    this.$message.error(res.data.error);
                                    return false;
                                } else {
                                    this.$router.push({ name: 'index' })
                                }
                            })
                            .catch(err => {
                                this.$message.error(`${err.message}`)
                            })
                    } else {
                        this.$message.error('表单验证失败!')
                        return false;
                    }
                })
            } else if (this.checked == false) {
                // alert('请阅读服务使用政策以及隐私政策')
                Toast.fail('请阅读服务使用政策以及隐私政策');
            }
        },

        //点击眼睛改变
        eyes() {
            this.typePwd = this.typePwd === 'password' ? 'text' : 'password';
            this.npicon = this.npicon === 'iconfont icon-yanjing' ? 'iconfont icon-yanjing1' : 'iconfont icon-yanjing'
        },
        seceyes() {
            this.typePsd = this.typePsd === "passWord" ? "text" : "passWord";
            this.npsicon = this.npsicon === 'iconfont icon-yanjing' ? 'iconfont icon-yanjing1' : 'iconfont icon-yanjing'
        }
    },
    mounted() {

    }


}

</script>
<style scoped >
.prbtn {
    padding: 0 10px !important;
    width: 300px;
    margin-left: 5px;
    background: #D7D7D7;
    border: none;
    font-size: 16px;
}

.bj {
    width: 100%;
    height: 580px;
    background-image: url('@/static/picture/zuce.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 290px;
    box-sizing: border-box;

}

.bttns {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0;
}

.bttns .checkboxs {
    line-height: 8px;
    margin-right: 5px;
}

.biao .regines h1 {
    width: 100%;
    height: 60px;
    font-size: 48px;
    font-weight: bolder;
    letter-spacing: 21px;
}

.biao .regines .el-form-item__content .el-form-item__error {
    width: 100%;
    font-size: 15px;

}

.biao .regines .el-form-item__content .el-input__inner {

    height: 54px;
    line-height: 54px;
    font-size: 17px;
}

.biao .regines .el-form-item__content {
    margin: 0 !important;
    display: flex;
    padding: 0 267px;
    justify-content: center;
}

.biao .regines {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.biao {
    width: 890px;
    height: 600px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 12px 14px #ecf5fd;
    text-align: center;
}

.tips {
    width: 100%;
    height: 130px;
    line-height: 120px;
    margin: 90px auto 150px;
    text-align: center;
}

.tips span {
    font-size: 20px;
    color: #8d93ac;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .bttns {
        display: flex;
        align-items: stretch;
    }
}
</style>