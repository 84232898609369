<template>
    <div class="replace">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo">
            <el-form-item prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"
                    placeholder="请再次输入新密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" class="button" @click="nexts">提交</el-button>
            </el-form-item>
            <div class="zi">
                <van-divider :style="{ color: '#65748D', borderColor: '#8B94C8', fontSize: '16px' }">
                    服务源自于专注
                </van-divider>
            </div>
        </el-form>
    </div>
</template>
<script>
import { setNewpassWord } from '@/unitle/api'
import { Toast } from 'vant';

export default {
    name: 'replace',
    data() {

        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                pass: '',
                checkPass: '',
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],

            }
        };

    },
    methods: {
        nexts() {
            let params = {
                phone: localStorage.getItem('phone'),
                password: this.ruleForm.pass,
                code: localStorage.getItem('code')
            }
            setNewpassWord(params).then(res => {
                if (this.ruleForm.pass == '') {
                    Toast.fail('密码不能为空');
                } else if (this.ruleForm.pass != this.ruleForm.checkPass) {
                    Toast.fail('密码不一致');
                } else {
                    this.$router.push({ name: 'over' })
                }


            })
            localStorage.removeItem('phone')
            localStorage.removeItem('code')
        }
    },

}
</script>
<style  scoped>
.replace {
    width: 100%;
    height: 100%;
    position: relative;
    /* margin-top: -100px; */
    bottom: 80px;

}

.demo {
    width: 100%;
    height: 100%;
}

.demo .el-input__inner {
    height: 180px !important;
}

div {
    width: 410px;
    margin: 14px auto;

    display: flex;
    align-items: center;
}

.el-form-item__content {
    margin: auto !important;
}

.code {
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 58px;
    line-height: 58px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
}

.el-form-item__error {
    width: 100%;
    font-size: 15px;
    margin: 0;
    padding: 0;
    top: 90%;
    left: 0;
}

.el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 58px;
    line-height: 58px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
}


.el-button--primary {
    width: 410px;
    height: 50px;
    border: none;
    background: #EA5504;
    box-shadow: 0 10px 14px #9ccbf4;
    border-radius: 5px;
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 2px;
}


.tips {
    width: 100%;
    height: 130px;
    line-height: 120px;
    margin: 90px auto 150px;
    text-align: center;
}

span {
    font-size: 20px;
    color: #8d93ac;
}
</style>