<template>
    <div id="service">
        <ul class="header_top" ref="ul">
            <div class="titles">
                <p class="title_one">我们的服务</p>
                <p class="english_one">Our service</p>
            </div>
        </ul>
        <div class="room_oo">
            <div class="hezi">
                <div class="lump">
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/5.png')" alt="">
                    </div>
                    <div class="writing">
                        <h3>商店优化投放</h3>
                        <h5>主流安卓商店，苹果商店广告投放</h5>
                    </div>
                </div>
                <div class="piece">
                    <div class="writing">
                        <h3>竞价/信息流推广</h3>
                        <h5>主流搜索引擎、信息流代运营</h5>
                    </div>
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/4.png')" alt="">
                    </div>
                </div>
                <div class="lump">
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/2.png')" alt="">
                    </div>
                    <div class="writing">
                        <h3>网站建设</h3>
                        <h5>品牌网站建设，门户平台建设</h5>
                    </div>
                </div>
                <div class="piece">
                    <div class="writing">
                        <h3>小程序开发</h3>
                        <h5>微信小程序，百度小程序开发制作</h5>
                    </div>
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/6.png')" alt="">
                    </div>
                </div>
                <div class="lump">
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/3.png')" alt="">
                    </div>
                    <div class="writing">
                        <h3>App开发</h3>
                        <h5>专业双端开发团队，助力品牌成长</h5>
                    </div>
                </div>
            </div>
        </div>
        <Footderbox></Footderbox>
    </div>
</template>

<style  scoped>
#service {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.header_top {
    width: 100%;
    height: 926px;
    background-image: url(http://abc.zhongbaikj.com/guanwang/shouye_20230310094731.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.room_oo {
    width: 100%;
    height: 1900px;
    margin: 50px auto 0;
    background-color: #F7F8FC;
    text-align: center;
    /* overflow: hidden; */
}

.hezi {
    width: 1900px;
    height: 100%;
    margin: 0 auto;
}

.lump,
.piece {
    width: 100%;
    height: 390px;
    display: flex;
    margin: 10px auto;
    padding: 0 0 0 115px;
    box-sizing: border-box;
}

.lump .photo,
.piece .photo {
    width: 565px;
    height: 380px;
    overflow: hidden;
    margin: auto 0;
    border-radius: 25px;
}

.photo img {
    width: 100%;
    height: 100%;
}

.lump .writing,
.piece .writing {
    width: 1100px;
    height: 380px;
    margin: auto 0;
}

.piece {
    margin: -30px 0;
}

.lump .writing,
.piece .writing {
    width: 1100px;
    height: 380px;
    /* background-color: aqua; */
    margin: auto 0;
}

.writing h3 {
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #181819;
    margin: 81px 0 50px
}

.writing h5 {
    font-size: 35px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #181819;
    opacity: 0.67;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .room_oo {
        height: 69%;
    }

    .hezi {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}
</style>
<script>

import Footderbox from '@/components/Footderbox/footderbox.vue';
export default {
    name: 'service',
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {
        document.documentElement.scrollTop = 820
    },
    components: { Footderbox },


}
</script>
