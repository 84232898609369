<template>
    <div class="retrieve">
        <div class="bj">
            <div class="biao">
                <el-steps :active="active" align-center>
                    <el-step title="身份验证" description="请输入手机号进行身份验证"></el-step>
                    <el-step title="更改密码" description="请输入更改密码"></el-step>
                    <el-step title="完成" description="更改完成返回首页"></el-step>
                </el-steps>
                <router-view />
            </div>
            <div class="tips">
                <span>专业服务源自于专注的态度、不懈的的努力和持之以恒的坚持</span>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'retrieve',
    data() {
        return {
            active: 0,
        }
    },
    computed: {
    },
    methods: {
    },
    mounted() {

        if (this.active === 0) {
            this.$router.replace({ name: 'retone' })
        } else if (this.active === 1) {
            this.$router.replace({ name: 'replace' })
        } else if (this.active === 2) {
            this.$router.replace({ name: 'over' })
        }
    },
    beforeUpdate() {
        if (this.$route.name == 'retone') {
            this.active = 0
        } else if (this.$route.name == 'replace') {
            this.active = 1
        } else if (this.$route.name == 'over') {
            this.active = 2
        }
    },
    updated() {
        if (this.$route.name == 'retone') {
            this.active = 0
        } else if (this.$route.name == 'replace') {
            this.active = 1
        } else if (this.$route.name == 'over') {
            this.active = 2
        }
    }
}
</script>
<style  scoped>
.retrieve {
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}

.bj {
    width: 100%;
    height: 580px;
    background-image: url('@/static/picture/zuce.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 290px;
    box-sizing: border-box;
    /* overflow: hidden; */
}

.biao {
    width: 890px;
    height: 600px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 12px 14px #ecf5fd;
    text-align: center;
    overflow: hidden;
}

.biao .el-steps--horizontal {
    padding: 30px 60px 0;
    margin-top: 30px;
    margin-bottom: 120px;
}

.tips {
    width: 100%;
    height: 130px;
    line-height: 120px;
    margin: 90px auto 150px;
    text-align: center;


}

.tips span {
    font-size: 20px;
    color: #8d93ac;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .retrieve {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .bj {
        background-size: 100%;
        padding: 0;
        padding-top: 2rem;
        /* overflow: hidden; */
    }

    .biao {
        width: 90%;
        height: 1600px;
        z-index: 999;
    }

    .biao .el-steps--horizontal {
        transform: scale(0.8);
        /* margin-bottom: 40px; */
    }


}
</style>