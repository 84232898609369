<template>
    <div class="driedfoodInfo">
    </div>
</template>
<style  scoped></style>
<script>
export default {
    name: 'driedfoodInfo',
    data() {
        return {

        }
    }
}
</script>
