<template>
    <div class="about">
        <div class="header_top">
            <div class="titles">
                <p class="title_one">关于忠柏科技</p>
                <p class="english_one">About Uncle Chung</p>
            </div>
        </div>
        <div class="about_zb">
            <div class="brief">
                <div class="imgboxs">
                    <img v-lazy="require('../../static/picture/pic_11.png')" alt="">
                </div>
            </div>
            <div class="brieftext">
                <p class="textone">关于忠柏科技</p>
                <p class="texttow">
                    忠柏科技是一家安卓+iOS双端ASO技术服务型营销推广公司，长期服务于头部金融公司，深度研究金融市场获客玩法，公司提供和帮助客户进行网站，小程序、app等设计策划等服务外，同时提供专业的aso优化服务以及安卓和ios双端竞价代运营服务,协助客户分析产品,覆盖关键词,精准控量，从而更好的做到品效合一！
                </p>
                <div class="workbox">
                    <p class="work">我要合作</p>
                </div>
            </div>
        </div>
        <div class="worth_box">
            <div class="box_jz">
                <div class="worth">
                    <div class="picboxs">
                        <img v-lazy="require('../../static/picture/flag.png')" alt="">
                    </div>
                    <div class="photo">
                        <p class="photo_one">愿景与使命</p>
                        <p class="photo_tow">成为世界级的技术服务型推广公司</p>
                    </div>
                </div>
                <div class="worth">
                    <div class="picboxs">
                        <img v-lazy="require('../../static/picture/dataicon.png')" alt="">
                    </div>
                    <div class="photo">
                        <p class="photo_one">价值观</p>
                        <p class="photo_tow">诚信、客户至上、团队合作、变化与创新、职业化与学习力</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="development">
            <p class="biaoti">发展历程</p>
            <div class="spot">
                <div class="wire">

                </div>
            </div>
        </div>

        <Footderbox></Footderbox>
    </div>
</template>
<style scoped>
.about {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.about_zb {
    width: 1364px;
    /* height: 374px; */
    margin: 45px auto 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 14px 0px;
}


.about_zb .brief {
    position: relative;
    width: 500px;

    /* background-image: url(@/static/picture/pic_11.png); */
    box-sizing: border-box;
}

.about_zb .brief .imgboxs {
    height: 370px;
    width: 500px;
}

.brief img {
    width: 100%;
    height: 100%;
}

.brieftext {
    box-sizing: border-box;
    margin-left: 30px;
}

.brieftext .textone {
    line-height: 33px;
    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
}

.brieftext .texttow {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #212121;
    opacity: 0.76;
    white-space: initial;
    text-indent: 47px;
    margin: 60px 0;
    font-size: 24px;
}

.brieftext .workbox {
    width: 100%;
    margin-top: 15px;
}

.brieftext .workbox .work {
    width: 150px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background: linear-gradient(94deg, #F10F00 0%, #FC7B25 99%);
    opacity: 0.83;
    border-radius: 6px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
}

.room {
    margin-top: -210px;
    width: 100%;
    height: 290px;
    background-color: #FFFFFF;
}

.worth_box {
    width: 1900px;
    margin: 0 auto;
    height: 324px;
    background: #F7FAFD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box_jz {
    height: 100%;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.worth {
    width: 550px;
    height: 137px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.worth .picbox {
    flex: 1;
    height: 120px;
}

.worth .picbox img {
    width: 100%;
    height: 100%;
}





.worth .photo_vm {
    width: 140px;
    height: 137px;
}



.worth div .photo_one {
    width: 400px;
    font-weight: 600;
    font-size: 30px;
}

.worth div .photo_tow {
    flex: 1;
    width: 800px;
    font-size: 20px;
    margin-top: 10px;
}

.development {
    width: 1900px;
    height: 1400px;
    margin: 0 auto;
    background-color: #FFFFFF;
}

.development .biaoti {
    display: block;
    width: 150px;
    height: 34px;
    line-height: 34px;
    margin: 0 auto;
    padding-top: 103px;
    font-size: 34px;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
}

.development .spot {
    width: 1167px;
    height: 530px;
    background-image: url(@/static/picture/Mapicon.png);
    margin: 153px auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

.development .spot .wire {
    position: relative;
    height: 229px;
    margin: 0px 0 0 -29px;
    top: 80px;
    background-position: center;
    background-image: url(@/static/picture/Lineiocn.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.development .spot .wire .bing {
    padding-top: 120px;
    margin: 0 0 0 1100px;
    width: 128px;
    height: 96px;
}

.bing p {
    display: block;
    text-align: center;
    margin: 10px auto;
}

.bing p:nth-child(1) {
    margin: 0 auto 10px;
    width: 44px;
    height: 21px;
    text-align: center;
    line-height: 21px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
}

.bing p {
    margin: 5px auto;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(58, 58, 58, 0.7);
}

.map {
    width: 1900px;
    margin: 0 auto 28px;
    height: 550px;
    background: #F7FAFD;
}

.map .map_lx {
    display: block;
    width: 150px;
    height: 34px;
    line-height: 34px;
    margin: 0 auto;
    padding-top: 40px;
    font-size: 34px;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
}

.map .map_gao {
    width: 1627px;
    height: 322px;
    background-image: url(@/static/picture/Mapicon_1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 153px auto;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .about_zb {
        /* height: 800px; */
        width: 100%;
    }

    .worth_box {
        height: 600px;
    }

    .worth img {
        width: 300px;
        height: 300px;
    }

    .worth_box .worth {
        width: 1000px;
        height: 500px;
    }

    .worth div .photo_tow {
        width: 600px;
    }

    .box_jz {
        height: auto;
        padding: 0;
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .about_zb .brief {
        width: 4000px;
        height: 500px;
    }

    .brieftext .texttow {
        text-indent: 120px;
    }

    .brieftext .workbox .work {
        width: 40%;
        height: 80px;
        line-height: 80px;
    }

    .development .biaoti {
        width: 600px;
    }

    .map .map_lx {
        width: 600px;
    }


}
</style>

<script>

import Footderbox from '@/components/Footderbox/footderbox.vue';
export default {
    name: 'about',
    data() {
        return {

        };
    },

    methods: {

    },
    mounted() {
        document.documentElement.scrollTop = 820
    },
    components: { Footderbox }
}
</script>