<template>
    <div class="information">
        <div class="right">
            <div class="textTop">
                <p class="emg">Enjoy the investment</p>
                <p class="emg_tow">技术干货</p>
            </div>
            <div class="rombox">
                <div class="modular" v-for="(item, index) in dataShow" :key="index" v-show="content">
                    <div class="imger">
                        <img v-lazy="require('@/static/qie/zixun.png')" alt="">
                    </div>
                    <div class="textTitl" @click="modular(item.id)">
                        <p class=" textbox">{{ item.title }}</p>
                        <p class="textboxs">{{ item.briefly }}</p>
                        <div class="visitor">
                            <div class="roods">
                                <!-- <p class="jishu">技术干货</p>
                                <p class="rood">41554人阅读</p> -->
                                <p class="day">{{ item.author }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="titleText" v-show="title">
                    <div class="titbox">
                        <div class="breadcrumb">
                            <span @click="home">首页</span>
                            <i>></i>
                            <span @click="back">技术干货</span>
                            <i>></i>
                            <span>正文</span>
                        </div>
                        <h1>{{ article.title }}</h1>
                        <div class="time">
                            <!-- <p>2001.02.02</p> -->
                            <p>来源：</p>
                            <p>{{ article.author }}</p>
                        </div>
                        <hr>
                        <div class="chart">

                            <p v-html="article.text"></p>
                            <!-- <img src="" alt="" class="map"> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="paging" v-show="content">
                <div class="pag">
                    <input type="button" value="上一页" @click="prePage">

                    <span>共{{ arrAy.length }}条信息/共{{ pageNum }}页/第{{ currentPage + 1 }}页</span>
                    <input type="button" value="下一页" @click="nextPage">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Get_articles, View_content } from '@/unitle/api'

export default {
    data() {
        return {
            arrAy: [],
            // 所有页面的数据
            totalPage: [],
            // 每页显示数量
            pageSize: 5,
            // 共几页
            pageNum: 1,
            // 当前显示的数据
            dataShow: "",
            // 默认当前显示第一页
            currentPage: 0,
            //直接前往
            togo: 1,
            content: true,
            title: false,
            article: []
        }
    },
    mounted() {
        Get_articles().then(res => {

            this.arrAy = res.data.data

            // 根据后台数据的条数和每页显示数量算出一共几页,得0时设为1 ;
            this.pageNum = Math.ceil(this.arrAy.length / this.pageSize) || 1;
            for (let i = 0; i < this.pageNum; i++) {
                // 每一页都是一个数组 形如 [['第一页的数据'],['第二页的数据'],['第三页数据']]
                // 根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为5， 则第一页是1-5条，即slice(0,5)，第二页是6-10条，即slice(5,10)...
                this.totalPage[i] = this.arrAy.slice(this.pageSize * i, this.pageSize * (i + 1))
            }
            // 获取到数据后显示第一页内容
            this.dataShow = this.totalPage[this.currentPage];
        })
    },
    methods: {
        modular(idx) {

            View_content({ id: idx }).then(res => {
                if (res.status === 200) {
                    this.article = res.data.data
                }
            })
            if (this.content == true || this.title == false) {
                this.content = false
                this.title = true
            } else if (this.content == false || this.title == true) {
                this.content = true
                this.title = true
            }
        },
        back() {
            if (this.title == true || this.content == false) {
                this.title = false
                this.content = true
            } else if (this.title == false || this.content == true) {
                this.title = true
                this.content = false
            }
        },
        home() {
            this.$router.push('/')
        },
        // 下一页
        nextPage() {
            if (this.currentPage === this.pageNum - 1) return;
            this.dataShow = this.totalPage[++this.currentPage];
        },
        // 上一页
        prePage() {
            if (this.currentPage === 0) return;
            this.dataShow = this.totalPage[--this.currentPage];
        },
        //直接前往

        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },

    },


}
</script>
<style scoped>
.right {}

.right .textTop {
    margin-top: 70px;
    text-align: center;
}

.right .textTop .emg {
    font-size: 42px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #3E3C3C;
}

.right .textTop .emg_tow {
    margin-top: 14px;
    font-size: 30px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #3E3C3C;
}

.right .rombox {
    width: 100%;
    height: 1730px;
    margin-top: 40px;
    border: 2px solid #B9C1D6;
    border-top: none;
    border-bottom: none;
    border-right: none;
}

.right .rombox .modular {
    margin-left: 20px;
    margin-bottom: 20px;
    height: 310px;
    padding-top: 40px;
    box-sizing: border-box;
    background-color: #eae6e6;
    display: flex;
}

.right .rombox .modular .imger {
    width: 348px;
    height: 230px;
    float: left;
    margin-left: 18px;
}

.right .rombox .modular .imger img {
    width: 100%;
    height: 100%;
}

.right .rombox .modular .textTitl {
    float: left;
    height: 260px;
    width: 1190px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: left;
}

.right .rombox .modular .textTitl .textbox {
    margin-left: 100px;
    line-height: 40px;
    width: 100%;
    height: 40px;
    font-size: 28px;
    color: #070707;
}

.right .rombox .modular .textTitl .textbox:hover {
    text-decoration: underline;
}

.right .rombox .modular .textTitl .textboxs {
    margin-left: 100px;
    margin-top: 60px;
    line-height: 50px;
    width: 670px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #777373;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 900px;
}

.right .rombox .modular .textTitl .textboxs:hover {
    text-decoration: underline;
}

.right .rombox .modular .textTitl .roods {
    height: 30px;
    margin-top: 60px;
    margin-left: 80px;
    line-height: 24px;
}

.right .rombox .modular .textTitl .roods .jishu {
    width: 87px;
    height: 27px;
    float: left;
    text-align: center;
    margin: 0px 10px;
    background: #E06C67;
    opacity: 0.83;
    border-radius: 6px;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #D71820;
    line-height: 24px;
}

.right .rombox .modular .textTitl .roods .rood {
    float: left;
    width: 110px;
}

.right .rombox .modular .textTitl .roods .day {
    float: right;
    width: 130px;
}

.right .rombox .modular:hover {
    background-color: #f3e5e5;
    animation: 2s;
    cursor: pointer;
}

.right .rombox .titleText::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
}

.right .rombox .titleText {
    max-width: 1578px;
    height: 100%;
    overflow-y: scroll;
}

.right .rombox .titleText .titbox {
    min-width: 910px;
    width: 60%;
    height: 100%;
    margin: auto;
}

.right .rombox .titleText .titbox .breadcrumb {
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
}

.right .rombox .titleText .titbox .breadcrumb i {
    margin: 0 4px;
}

.right .rombox .titleText .titbox .breadcrumb span {
    cursor: pointer;
}

.right .rombox .titleText .titbox .breadcrumb span:nth-of-type(1) {
    font-weight: 600;
}

.right .rombox .titleText .titbox .breadcrumb span:nth-of-type(2) {
    font-weight: 600;
}

.right .rombox .titleText .titbox h1 {
    word-break: break-all;
    word-wrap: break-word;
    margin-bottom: 40px;
    text-indent: 24px;
    font-size: 44px;
}

.right .rombox .titleText .titbox .time {
    display: flex;
    width: 100%;
    height: 24px;
    line-height: 24px;
}

.right .rombox .titleText .titbox .time p {
    margin: 0 5px;
    font-size: 18px;
    color: rgb(0, 0, 0);
}

.right .rombox .titleText .titbox hr {
    width: 100%;
    color: #d3d3d3;
    margin: 2px auto;
}

.right .rombox .titleText .titbox .chart {
    margin: 10px auto 130px;
    text-align: left !important;
}

.right .rombox .titleText .titbox .chart h4 {
    margin: 20px auto;
    padding-left: 44px;
}

.right .rombox .titleText .titbox .chart img {
    width: 100%;
    height: 960px;
    background-color: #b2b2b2;
}

.right .rombox .titleText .titbox .chart p {
    text-indent: 24px;
    box-sizing: border-box;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 18px;
    color: #000;
    display: block;
    margin: 16px auto;
}

.right .paging {
    width: 100%;
    height: 40px;
    margin-bottom: 80px;
}

.right .paging .pag {
    width: 500px;
    margin-left: 280px;
    height: 100%;
}

.right .paging .pag input {
    width: 60px;
    height: 100%;
    border: 1px solid #ccc;
    margin: 0 4px;
    border-radius: 5px;
    cursor: pointer;
}

.right .paging input:nth-of-type(2) {

    width: 60px;
    padding: 2px;
    box-sizing: border-box;

}

.right .paging .pag input:hover {
    background-color: #BD130C;
    color: #fff;
    transition: all 1s;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .driedfood .left .side .surface {
        height: 180px !important;
    }

    .right .rombox .modular .textTitl .textbox {
        line-height: normal;
    }

    .right .rombox .modular .textTitl .textboxs {
        line-height: normal;
        height: auto;
    }

    .right .rombox .modular .textTitl .roods {
        height: auto;
        margin: 0;
        line-height: normal;
    }

    .right .rombox .titleText .titbox {
        width: 82%;
    }

    .day {
        margin-right: 150px;
    }

    .right .paging .pag {
        width: 80%;
        margin: 0 auto;
    }

    .right .paging .pag input {
        width: 240px;
        height: 150px;
        font-size: 12px;
    }
}
</style>
