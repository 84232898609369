import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// import user from '../views/Retrieve/retone/modules/index'

export default new Vuex.Store({
  state: {
    token: '',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    // user
  }
})
