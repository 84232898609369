<template>
    <div class="over">
        <form action="">
            <div class="title">
                <h1>
                    更改成功
                </h1>
            </div>
            <div>
                <input type="button" value="确定" class="button" @click="over">
            </div>
            <div class="zi">
                <van-divider :style="{ color: '#65748D', borderColor: '#8B94C8', fontSize: '16px' }">
                    服务源自于专注
                </van-divider>
            </div>

        </form>

    </div>
</template>
<script>
export default {
    name: 'over',
    data() {
        return {

        }
    },
    methods: {
        over() {
            this.$router.replace({ name: 'index' })
        }
    }
}
</script>
<style  scoped>
.title {
    text-align: center;
    position: relative;
    bottom: 80px;
    line-height: 70px;
}

form {
    width: 100%;
    height: 100%;
}

form div {
    width: 410px;
    margin: 20px auto;
    height: 68px;
}

form div span {
    width: 40px;
    height: 40px;
    position: relative;
    font-size: 30px;
    right: -180px;
    top: -40px;
}

form div input {
    width: 410px;
    height: 50px;
    border: none;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    text-indent: 5px;
}

form .button {
    width: 410px;
    height: 50px;
    border: none;
    background: #EA5504;
    box-shadow: 0 10px 14px #9ccbf4;
    border-radius: 5px;
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 2px;
}

.tips {
    width: 100%;
    height: 130px;
    line-height: 120px;
    margin: 90px auto 150px;
    text-align: center;
}

.tips span {
    font-size: 20px;
    color: #8d93ac;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    form {
        /* height: 100%; */
    }

    form div {
        /* margin-top: 140px; */
        width: 90%;
        margin: 200px auto;
    }

    form .button {
        width: 500px;
        height: 150px;
        margin: 0 auto;

    }
}
</style>