var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"service"}},[_c('ul',{ref:"ul",staticClass:"header_top"},[_vm._m(0)]),_c('div',{staticClass:"room_oo"},[_c('div',{staticClass:"hezi"},[_c('div',{staticClass:"lump"},[_c('div',{staticClass:"photo"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../static/qie/5.png')),expression:"require('../../static/qie/5.png')"}],attrs:{"alt":""}})]),_vm._m(1)]),_c('div',{staticClass:"piece"},[_vm._m(2),_c('div',{staticClass:"photo"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../static/qie/4.png')),expression:"require('../../static/qie/4.png')"}],attrs:{"alt":""}})])]),_c('div',{staticClass:"lump"},[_c('div',{staticClass:"photo"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../static/qie/2.png')),expression:"require('../../static/qie/2.png')"}],attrs:{"alt":""}})]),_vm._m(3)]),_c('div',{staticClass:"piece"},[_vm._m(4),_c('div',{staticClass:"photo"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../static/qie/6.png')),expression:"require('../../static/qie/6.png')"}],attrs:{"alt":""}})])]),_c('div',{staticClass:"lump"},[_c('div',{staticClass:"photo"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../static/qie/3.png')),expression:"require('../../static/qie/3.png')"}],attrs:{"alt":""}})]),_vm._m(5)])])]),_c('Footderbox')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titles"},[_c('p',{staticClass:"title_one"},[_vm._v("我们的服务")]),_c('p',{staticClass:"english_one"},[_vm._v("Our service")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"writing"},[_c('h3',[_vm._v("商店优化投放")]),_c('h5',[_vm._v("主流安卓商店，苹果商店广告投放")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"writing"},[_c('h3',[_vm._v("竞价/信息流推广")]),_c('h5',[_vm._v("主流搜索引擎、信息流代运营")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"writing"},[_c('h3',[_vm._v("网站建设")]),_c('h5',[_vm._v("品牌网站建设，门户平台建设")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"writing"},[_c('h3',[_vm._v("小程序开发")]),_c('h5',[_vm._v("微信小程序，百度小程序开发制作")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"writing"},[_c('h3',[_vm._v("App开发")]),_c('h5',[_vm._v("专业双端开发团队，助力品牌成长")])])
}]

export { render, staticRenderFns }