import service from "./axios"
export function coDe(params) {
    return service({
        url: `/user/code`,
        method: 'get',
        params: params
    })
}
export function addUsers(params) {
    return service({
        url: `/user/add`,
        method: 'post',
        params: params
    })
}
export function Login(params) {
    return service({
        url: `/user/login`,
        method: 'post',
        params: params,

    })
}
export function Authentication(params) {
    return service({
        url: `/user/GaiCode`,
        method: 'get',
        params: params,
    })
}
export function setNewpassWord(params) {
    return service({
        url: `/user/revise`,
        method: 'post',
        params: params,
    })
}
export function Get_articles(params) {
    return service({
        url: `/essay/selectTitle`,
        method: 'get',
        params: params,
    })
}
export function View_content(params) {
    return service({
        url: `/essay/selectById`,
        method: 'get',
        params: params,
    })
}
