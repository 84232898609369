var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"center",staticStyle:{"margin":"0 auto"}},[_c('dl',[_c('dt',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.about}},[_vm._v("关于我们")])]),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.service}},[_vm._v("公司服务")])]),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('dl',[_c('dt',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.cooperation}},[_vm._v("我要合作")])]),_vm._m(6),_vm._m(7)])]),_vm._m(8),_c('div',{staticClass:"txt"},[_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.19720258.J_9220772140.110.255b76f4SlscHK"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../../static/qie/gonan.png')),expression:"require('../../static/qie/gonan.png')"}],staticStyle:{"position":"relative","top":"4px"},attrs:{"alt":""}}),_vm._v("浙公网安备 33020502001020号")]),_c('a',{staticClass:"ICP",attrs:{"href":"https://beian.miit.gov.cn/?spm=5176.19720258.J_9220772140.111.6a2776f4WZpUSU"}},[_vm._v("浙ICP备2022037247号-1")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("公司介绍")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("加入我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("商店优化投放")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("竞价/信息流推广")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("小程序开发")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("邮箱:douht@ningbozb.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("地址:宁波市江北区华业街113号A-342号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dd',{staticClass:"dd"},[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"javascript:;"}},[_vm._v("地址:宁波市江北区华业街113号A-342号")])])
}]

export { render, staticRenderFns }