import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import qs from 'qs'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont/iconfont'
import '@/assets/iconfont/iconfont.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from './unitle/axios';
import 'lib-flexible'
import { Lazyload } from 'vant';
Vue.use(Lazyload)

router.onError(error => {
  const pattern = /Loading chunk (\w)+ failed/g
  const isError = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isError) {
    router.replace(targetPath)
  }
})

Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$axios = axios


Vue.prototype.$qs = qs
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')