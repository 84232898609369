<template>
    <div class="advantage">
        <div class="header_top">
            <div class="titles">
                <p class="title_one">我们的优势</p>
                <p class="english_one">Our advantage</p>
            </div>
        </div>
        <div class="hezibox">
            <div class="romes">
                <div class="text">
                    <p class="text_one">行业经验丰富</p>
                    <p class="text_tow">团队在技术和营销领域拥有多年互联网营销经验。丰富的行业经验和解决方案有助于营销目标达成。</p>
                </div>
                <div class="photoes">
                    <img v-lazy="require('../../static/picture/advantage_1.png')" alt="">
                </div>
            </div>
            <div class="romes">
                <div class="photoes">
                    <img v-lazy="require('../../static/picture/advantage_2.png')" alt="">
                </div>
                <div class="text">
                    <p class="text_one">服务流程精细</p>
                    <p class="text_tow">基于行业经验，细分服务执行环节为独特节点流程，方便针对节点考核和优化！</p>
                </div>
            </div>
            <div class="romes">
                <div class="text">
                    <p class="text_one">团队精益执行</p>
                    <p class="text_tow">自建了底层执行优化与技术团队执行环节不外包提升项目执行效率和精益项目管控。</p>
                </div>
                <div class="photoes">
                    <img v-lazy="require('../../static/picture/advantage_3.png')" alt="">
                </div>
            </div>
        </div>
        <Footderbox></Footderbox>
    </div>
</template>
<style>
* {
    margin: 0;
    padding: 0;
}

.advantage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

body {
    width: 100%;
    background-color: #F7F8FC;
}


.hezibox {
    width: 100%;
    height: 2200px;
    margin: 50px auto 10px;
    background-color: #F7F8FC;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.romes {
    width: 1620px;
    height: 407px;
    /* margin: 0px auto 171px; */
    padding-top: 6px;
    background-color: #ffffff;
    /* box-shadow: 0px 4px 0px 0px rgba(255, 255, 255, 0.51); */
    display: flex;
    justify-content: space-around;

}


.romes .text {
    width: 599px;
    height: 394px;
    white-space: initial;
}

.romes .text .text_one {
    display: block;
    width: 300px;
    height: 45px;
    line-height: 45px;
    margin: 64px auto 40px;
    font-size: 48px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #181819;
}

.romes .text .text_tow {
    display: block;
    width: 600px;
    /* height: 101px; */
    line-height: 50px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #181819;
    white-space: initial;
    text-align: left;
    text-indent: 60px;
}

.photoes {
    width: 576px;
    height: 394px;
    overflow: hidden
}

.photoes img {
    transition: all 1s;
    width: 100%;
    height: 100%;
}

.romes:hover img {
    transition: all 1s;
    transform: scale(1.5);
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .romes {
        height: 580px;
    }

    .romes .photoes {
        height: 100%;
        width: 720px;
    }

    .romes .text .text_one {
        width: auto;
        text-align: center;
    }

    .romes .text .text_tow {
        height: 100%;
        font-size: 12px;
        line-height: 80px;
        text-indent: 140px;
        text-align: justify;
    }

    .hezibox {
        height: 69%;
    }
}
</style>
<script>
import Footderbox from '@/components/Footderbox/footderbox.vue';

export default {
    name: "advantage",
    data() {
        return {};
    },
    methods: {},
    mounted() {
        document.documentElement.scrollTop = 820
    },
    components: { Footderbox }
}
</script>
