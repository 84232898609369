<template>
    <div class="driedfood">
        <div class="header_top">
            <div class="titles">
                <p class="title_one">技术干货</p>
                <p class="english_one">Technical work</p>
            </div>
        </div>
        <div class="mian">
            <div class="con">
                <van-sidebar v-model="activeKey" @change="change">
                    <left-top-box :backImg="backImg"></left-top-box>
                    <van-sidebar-item v-for="(item, index) in list" :key="index" :title="item.name"
                        @click="sidebarClick(index)" />
                </van-sidebar>
                <keep-alive>
                    <contents :TitleData="[namesTitle, EnglishTitle, backImg]" ref="contents">
                    </contents>
                </keep-alive>
                <!-- <div class="left">
                <div class="side">
                    <div class="surface" v-for="(item, nums) in list" :key="nums" @click="change(nums)" tabindex=" 0">
                        {{ item.name }}
                    </div>
                </div>
            </div> -->
                <!-- <router-view /> -->
            </div>
        </div>
        <Footderbox></Footderbox>
    </div>
</template>
<script>
import Footderbox from '@/components/Footderbox/footderbox.vue';
import Contents from './components/Contents.vue';
import LeftTopBox from './components/Left-top-Box.vue';
export default {
    components: {
        LeftTopBox,
        Footderbox,
        Contents
    },
    name: 'driedfood',
    data() {
        return {
            namesTitle: '',
            EnglishTitle: '',
            backImg: '',
            activeKey: 0,
            list: [
                // '技术干货', '业内资讯', '行业报告'
                {
                    English: 'Technical dry goods',
                    name: '技术干货',
                    img: require('@/static/qie/ganhuo.png')
                },
                {
                    English: 'Industry information',
                    name: '业内资讯',
                    img: require('@/static/qie/zixun.png')
                },
                {
                    English: 'Industry reports',
                    name: '行业报告',
                    img: require('@/static/qie/baogao.png')
                },
            ]
        }
    },
    methods: {

        sidebarClick(index) {
            this.$refs.contents.goback()
        },

        change(index) {
            sessionStorage.setItem('activeKey', index)
            if (index == 0) {
                this.namesTitle = this.list[0].name
                this.EnglishTitle = this.list[0].English
                this.backImg = this.list[0].img
            } else if (index == 1) {
                this.namesTitle = this.list[1].name
                this.EnglishTitle = this.list[1].English
                this.backImg = this.list[1].img
            } else if (index == 2) {
                this.namesTitle = this.list[2].name
                this.EnglishTitle = this.list[2].English
                this.backImg = this.list[2].img
            }
            // this.$refs.contents.goback()
            // if (nums == 0) {
            //     this.$router.replace({ name: 'romm' })
            // } else if (nums == 1) {
            //     this.$router.replace({ name: 'information' })
            // } else if (nums == 2) {
            //     this.$router.replace({ name: 'report' })
            // }
        },
    },
    mounted() {
        this.activeKey = sessionStorage.getItem('activeKey')
        this.namesTitle = this.list[0].name
        this.EnglishTitle = this.list[0].English
        this.backImg = this.list[0].img
        // if (this.$route.path == '/driedfood/romm') {
        //     this.$router.replace({ name: 'driedfood' })
        // } else if (this.$route.path == '/driedfood/information') {
        //     this.$router.replace({ name: 'driedfood' })
        // } else if (this.$route.path == '/driedfood/report') {
        //     this.$router.replace({ name: 'driedfood' })
        // }

        document.documentElement.scrollTop = 820

    },
    beforeUpdate() {
        // this.getIndex()

    },
    components: {
        Footderbox,
        Contents,
        LeftTopBox
    }
}
</script>
<style  scoped>
.driedfood {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.driedfood .mian .con {
    width: 97%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.driedfood .mian .con .van-sidebar {
    width: auto;
    margin-top: 50px;
}

.driedfood .mian .con .van-sidebar .van-sidebar-item {
    font-size: 18px;
}

.driedfood .left .leftbox .driedfood .left .side {
    width: 100%;
    height: 100%;
}

.driedfood .left .side .surface {
    width: 100%;
    height: 80px;
    color: #000;
    line-height: 80px;
    font-size: 20px;
    text-align: center;
    background-color: #F6F8FD;
    cursor: pointer;
}

.driedfood .left .side .surface:hover {
    background-color: #B9C1D7;
}

.driedfood .left .side .surface:focus {
    background-color: #B9C1D7;
}

.driedfood .left {
    width: 300px;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {


    .driedfood .left .side .surface {
        height: 180px !important;
        line-height: 180px;
    }
}
</style>
