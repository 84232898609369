import axios from "axios";


const service = axios.create({
    baseURL: '',
    timeout: 3000,
    // withCredentials: false,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Content-Type': 'application/json'
    }
})


service.interceptors.request.use(
    config => {

        const data = localStorage.getItem('token')
        if (data) {
            config.headers.Authorization = data
        }
        return config
    },
    error => {
        return Promise.reject(error)
    })




service.interceptors.response.use(
    response => {
        // endLoading()

        // console.log(response.headers.token);
        if (response.headers.token) {
            sessionStorage.setItem('token', response.headers.token);
        }
        if (response.data.code == 1001) {
            return Promise.reject(new Error('手机号不存在'))
        } else if (response.data.code == 1002) {
            return Promise.reject(new Error('用户名或者密码错误'))
        } else if (response.data.code == 1003) {
            return Promise.reject(new Error('手机号和验证码不能为空'))
        } else if (response.data.code == 1004) {
            return Promise.reject(new Error('验证码错误'))
        } else if (response.data.code == 1005) {
            return Promise.reject(new Error('用户名不存在'))
        } else if (response.data.code == 1006) {
            return Promise.reject(new Error('该手机号已经注册过了'))
        } else if (response.data.code == 401) {
            // window.localStorage.removeItem('token');
            // router.push('/index')
            return Promise.reject(new Error('登录凭证无效或者过期，请重新登录'))
        } else if (response.data.code == 201) {
            return Promise.reject(new Error('网络请求失败'))
        }

        return response;
    },
    error => {
        if (error.message) {
            alert('服务器开小差了，请稍后再试!')
        }
        return Promise.reject(error)
    }

)
export default service;