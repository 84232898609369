<template>
    <div class="contents">
        <header>
            <h1 class="English">{{ TitleData[1] }}</h1>
            <h3>{{ TitleData[0] }}</h3>
        </header>
        <main v-if="main_show">
            <div class="contents-main" v-for="(item, index) in dataShow" :key="index" @click="goinfo(item.id)">
                <div class="left"><img :src="TitleData[2]" alt="" class="imgList"></div>
                <div class="right">
                    <h5>{{ item.title }}</h5>
                    <p>{{ item.briefly }}</p>
                    <div class="use">{{ item.author }}</div>
                </div>
            </div>
        </main>
        <div class="titleText" v-if="show">
            <div class="titbox">
                <div class="breadcrumb">
                    <!-- <span @click="goback">首页<i>></i></span> -->
                    <span @click="goback">{{ TitleData[0] }} <i>></i></span>
                    <span>正文</span>
                </div>
                <h1>浅谈APP的用户增长体系搭建</h1>
                <div class="time">
                    <p>来源：</p>
                    <p>{{ article.author }}</p>
                </div>
                <hr>
                <div class="chart">
                    <p v-html="article.text"></p>
                </div>
            </div>
        </div>
        <div class="paging" v-show="content">
            <div class="pag">
                <input type="button" value="上一页" @click="prePage">
                <span class="txt">共{{ arrAy.length }}条信息/共{{ pageNum }}页/第{{ currentPage + 1 }}页</span>
                <input type="button" value="下一页" @click="nextPage">
            </div>
        </div>
    </div>
</template>
<style  scoped>
.contents {
    width: auto;
    border-left: 1px solid #ccc;
    flex: 1;
    box-sizing: border-box;
    padding: 0 40px;
    margin-left: 6px;
}

.contents header {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contents header .English {
    font-size: 40px;
    font-weight: 600;
    color: #333;
    margin: 30px 0;
}

.contents header h3 {
    font-size: 30px;
    font-weight: 600;
    color: #333;
}

.contents header .el-breadcrumb {
    margin: 20px 0;
}

.contents main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contents-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgb(239 239 239);
    height: 248px;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px 0;
    cursor: pointer;
}

.contents-main .left {
    width: 340px;
    height: 100%;
}

.contents-main .left img {
    width: 100%;
    height: 100%;
}

.contents-main .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-left: 60px;
    flex: 1;
}

.contents-main .right h5 {
    font-size: 30px;
    text-decoration: underline;
    font-weight: normal;
}

.contents-main .right p {
    font-size: 16px;
    color: #646464;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 800px;
}

.contents-main .right .use {
    align-self: flex-end;
}

.titleText::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
}

.titleText {
    max-width: 1578px;
    height: 100%;
    overflow-y: scroll;
}

.titleText .titbox {
    min-width: 910px;
    width: 60%;
    height: 100%;
    margin: auto;
}

.titleText .titbox .breadcrumb {
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
}

.titleText .titbox .breadcrumb i {
    margin: 0 4px;
}

.titleText .titbox .breadcrumb span {
    cursor: pointer;
}

.titleText .titbox .breadcrumb span:nth-of-type(1) {
    font-weight: 600;
}

.titleText .titbox .breadcrumb span:nth-of-type(2) {
    font-weight: 600;
}

.titleText .titbox h1 {
    word-break: break-all;
    word-wrap: break-word;
    margin-bottom: 40px;
    text-indent: 24px;
    font-size: 44px;
}

.titleText .time {
    display: flex;
    width: 100%;
    height: 24px;
    line-height: 24px;


}

.titleText .titbox .time p {
    margin: 0 5px;
    font-size: 18px;
    color: rgb(0, 0, 0);
}

.titleText .titbox hr {
    width: 100%;
    color: #d3d3d3;
    margin: 2px auto;
}

.titleText .titbox .chart {
    margin: 10px auto 130px;
    text-align: left !important;
}

.titleText .titbox .chart h4 {
    margin: 20px auto;
    padding-left: 44px;
}

.titleText .titbox .chart img {
    width: 100%;
    height: 960px;
    background-color: #b2b2b2;
}

.titleText .titbox .chart p {
    text-indent: 24px;
    box-sizing: border-box;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 18px;
    color: #000;
    display: block;
    margin: 16px auto;
}

.contents .paging {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 0;
}

.contents .paging .pag {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contents .paging .pag .txt {
    font-size: 18px;
}

.contents .paging .pag input {
    border: 1px solid #ccc;
    margin: 0 4px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 4px;
}

.contents .paging .pag input:hover {
    background-color: #BD130C;
    color: #fff;
    /* transition: all 1s; */
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .titleText {
        max-height: 100vh;
    }
}
</style>
<script>
import { Get_articles, View_content } from '@/unitle/api'
export default {
    props: ['TitleData'],
    name: 'contents',
    data() {
        return {
            main_show: true,
            arrAy: [],
            // 所有页面的数据
            totalPage: [],
            // 每页显示数量
            pageSize: 5,
            // 共几页
            pageNum: 1,
            // 当前显示的数据
            dataShow: "",
            // 默认当前显示第一页
            currentPage: 0,
            //直接前往
            city: 1,
            content: true,
            article: [],
            show: false,
            pageIndex: null
        }
    },
    watch: {
        TitleData(news, olds) {
            return news
        }
    },
    mounted() {
        this.article = JSON.parse(sessionStorage.getItem('article'))
        if (this.$route.query.id) {
            this.show = true
            this.content = false
            this.main_show = false
        } else {
            this.show = false
        }
        Get_articles().then(res => {
            if (res.status === 200) {
                this.arrAy = res.data.data
                // 根据后台数据的条数和每页显示数量算出一共几页,得0时设为1 ;
                this.pageNum = Math.ceil(this.arrAy.length / this.pageSize) || 1;
                for (let i = 0; i < this.pageNum; i++) {
                    // 每一页都是一个数组 形如 [['第一页的数据'],['第二页的数据'],['第三页数据']]
                    // 根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为5， 则第一页是1-5条，即slice(0,5)，第二页是6-10条，即slice(5,10)...
                    this.totalPage[i] = this.arrAy.slice(this.pageSize * i, this.pageSize * (i + 1))
                }
                // 获取到数据后显示第一页内容
                this.dataShow = this.totalPage[this.currentPage];
            }
        })
    },
    methods: {
        goback() {
            this.$router.replace({ path: '/driedfood' })
            this.show = false
            this.content = true
            this.main_show = true
            // console.log(this.TitleData)
        },
        goinfo(id) {
            View_content({ id: id }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem('article', JSON.stringify(res.data.data))
                }
                let page = this.$router.resolve({
                    path: '/driedfood/driedfoodInfo/?id=' + id,
                })
                window.open(page.href, '_blank');
            })
        },
        // 下一页
        nextPage() {
            if (this.currentPage === this.pageNum - 1) return;
            this.dataShow = this.totalPage[++this.currentPage];
            window.scrollTo(0, 900); //页面滚动到顶部
        },
        // 上一页
        prePage() {
            if (this.currentPage === 0) return;
            this.dataShow = this.totalPage[--this.currentPage];
            window.scrollTo(0, 900); //页面滚动到顶部
        },
    }
}
</script>