<template>
    <div class="side" v-show="flag_scroll">
        <div class="menu">
            <ul>
                <li>
                    <a href="JavaScript:;" @click="pop()">
                        <p class="apply">申请合作</p>
                    </a>
                </li>
                <li>
                    <a href="JavaScript:;">
                        <span class="iconfont icon-erweima" style="font-size:30px"></span>
                        <span
                            style="width:26px;height:2px;display:block;background-color: rgb(0, 0, 0);margin:-6px auto 0;opacity: 0.7;"></span>
                        <!-- <div class="towDimension">
                            <img src="@/static/picture/QR_1.png" alt="">
                        </div> -->
                    </a>
                </li>
                <li>
                    <a href="JavaScript:;">
                        <span class="iconfont icon-dianhuatianchong" style="font-size: 30px;"></span>
                        <div class="cus_ice">
                            <h1>客服电话</h1>
                            <p><span class="iconfont icon-huchudianhuatianchong">0574-87475622</span></p>
                            <i>为您提供vip解决方案、行业合作（24小时）。</i>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <button class="backTop" @click="backTop">
            <span class="iconfont icon-zhiding"></span>
        </button>
    </div>
</template>

<script>
export default {
    name: "HomeView",
    data() {
        return {
            flag_scroll: false,
            tification: false,
            scroll: 0,
            currentTab: 0,
            tabIndex: 0,
        }
    },
    methods: {
        backTop() {
            console.log(123);
            const that = this;
            let timer = setInterval(() => {
                let upSpeed = Math.floor(-that.scrollTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + upSpeed;
                if (that.scrollTop === 0) {
                    clearInterval(timer);
                }
            }, 20)

        },

        scrollToTop() {
            //alert('进入');，添加次语句可用于测试此时的浏览器窗口距离顶部是否》900
            //计算距离顶部的高度（高度大于900，显示返回按钮，否则隐藏）
            const that = this;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            that.scrollTop = scrollTop;
            if (that.scrollTop > 900) {
                that.flag_scroll = true;

            } else {
                that.flag_scroll = false;

            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.scrollToTop, true)
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollToTop)
    },
}
</script>
<style lang="less" scoped>
.side {
    width: 60px;
    height: 184px;
    border-radius: 5px;
    position: fixed;
    top: 500px;
    right: 10px;
    z-index: 99;

    .menu {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: 5px 4px 10px #d2d0d0;
        overflow: hidden;
        text-align: center;

        ul {
            li {
                width: 100%;
                height: 60px;
                margin: 0px auto 2px;
                line-height: 60px;

                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    color: #313234;
                    opacity: 0.9;

                    .apply {
                        width: 60px;
                        height: 100%;
                        margin: auto;
                        font-size: 22px;
                        line-height: 30px;
                        font-weight: 600;
                        background-color: red;
                        color: #fff;
                    }

                    .towDimension {
                        display: none;
                        width: 128px;
                        height: 128px;
                        margin-top: 5px;
                        position: absolute;
                        bottom: 10px;
                        right: 66px;

                    }

                    .cus_ice {
                        width: 500px;
                        height: 180px;
                        padding: 15px 0;
                        display: none;
                        box-sizing: border-box;
                        border-radius: 50px;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        position: absolute;
                        right: 66px;
                        top: 78px;

                        p,
                        h1,
                        i {
                            width: 100%;
                            height: 40px;
                            display: block;
                            text-align: left;
                            text-indent: 20px;
                            line-height: 40px;
                            margin: 0 auto 10px;

                            span {
                                font-size: 40px;
                                color: red;
                                font-weight: 600;
                            }
                        }

                        h1 {
                            font-size: 40px;
                            font-weight: 900;
                        }

                        i {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }

                a:hover {
                    background-color: rgb(223, 82, 82);
                    transition: all 1s;

                    p,
                    span {
                        color: #fff;
                    }

                    .towDimension {
                        display: block;
                    }

                    .cus_ice {
                        display: block;
                    }
                }
            }
        }
    }

    .backTop {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        margin: 6px auto 0;
        overflow: hidden;
        font-size: 24px;
        z-index: 100;
        border: none;
        box-shadow: 5px 4px 10px #d2d0d0;
        line-height: 26px;
        cursor: pointer;

        span {
            font-size: 60px;
            line-height: 50px;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }
    }
}
</style>