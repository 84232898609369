<template>
    <div class="leftbox">
        <img :src="backImg" alt="" class="imgList">
    </div>
</template>
<style  scoped>
.leftbox {
    width: 340px;
    height: 228px;
    background-color: #425383;
}

.leftbox img {
    height: 100%;
    width: 100%;
}
</style>
<script>
export default {
    props: ['backImg'],
    name: 'leftbox',
    data() {
        return {
        }
    },
    watch: {
        backImg(news, olds) {
            return news
        }
    },
    mounted() {

    }
}
</script>