<template>
    <div class="retone">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demoForm">
            <el-form-item prop="phone" :rules="[
                { required: true },
                { type: 'number', message: '手机号必须为数字值' }
            ]">
                <el-input v-model.number="ruleForm.phone" maxlength="11" placeholder="请输入用户手机号码"></el-input>
            </el-form-item>
            <el-form-item prop="code">
                <!-- <el-input type="text" v-model.number="ruleForm.code" placeholder="手机验证码"></el-input> -->
                <input type="text" v-model.number="ruleForm.code" maxlength="4" placeholder="手机验证码" class="code">
                <el-button @click="changeMsg" :disabled="ruleForm.disabled"
                    style="padding: 0 12px;height: 58px;margin-left: 10px;background: rgb(235 235 235);">{{
                        ruleForm.message
                    }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="next" :disabled="disabled">{{ ruleForm.next }}</el-button>
            </el-form-item>
            <div class="divider">
                <van-divider :style="{ color: '#65748D', borderColor: '#8B94C8', fontSize: '16px' }">
                    服务源自于专注
                </van-divider>
            </div>
        </el-form>

    </div>
</template>
<script>
import { Authentication } from '@/unitle/api'
import { Toast } from 'vant';

export default {
    name: 'retone',
    data() {
        var telphone = (rule, value, callback) => {
            var pattern = new RegExp("^1[34578][0-9]{9}$", 'i');
            if (!pattern.test(this.ruleForm.phone)) {
                callback(new Error('请输入正确手机号'));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                phone: '',
                message: '发送验证码',
                next: '下一步',
                code: '',
                disabled: false
            },
            rules: {
                phone: [
                    { validator: telphone, trigger: 'blur' }
                ]
            },
            time: 60,
            disabled: false
        }
    },
    methods: {
        changeMsg() {
            let params = {
                phone: this.ruleForm.phone
            }
            if (this.ruleForm.phone == '') {
                Toast.fail('请输入手机号');
            } else {
                Authentication(params)
                    .then((res) => {
                        // console.log(res);
                        localStorage.setItem('phone', res.config.params.phone)
                        localStorage.setItem('code', res.data.data)
                        this.ruleForm.disabled = true;
                        this.ruleForm.message = this.time + 's后重新获取';
                        var timer = setInterval(() => {
                            this.time--;
                            this.time = this.time >= 0 ? this.time : 0;
                            this.ruleForm.message = this.time + 's后重新获取';
                            if (this.time == 0) {
                                this.ruleForm.message = '重新获取';
                                this.ruleForm.disabled = false;
                                clearInterval(timer);
                                this.time = 60;
                            }
                        }, 1000);

                    })

            }

        },
        next() {
            if (this.ruleForm.phone == '' || this.ruleForm.code == '') {
                Toast.fail('手机号或验证码未输入');
            } else {
                this.$router.push({ name: 'replace' })
            }
           
        },
    },

}

</script>
<style  scoped>
.retone {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 80px;
}

.demoForm {
    width: 100%;
    height: 100%;
}

.demoForm div {
    width: 410px;
    margin: 14px auto;
    display: flex;
    align-items: center;
}

.demoForm div .el-form-item__content {
    margin: 0 !important;
}

.demoForm div .el-form-item__content .code {
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 58px;
    line-height: 58px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
}

.demoForm div .el-form-item__content .el-form-item__error {
    width: 100%;
    font-size: 15px;
    margin: 0;
    padding: 0;
    top: 90%;
    left: 0;
}

.demoForm div .el-form-item__content .el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 58px;
    line-height: 58px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
}

.demoForm .el-button--primary {
    width: 410px;
    height: 50px;
    border: none;
    background: #EA5504;
    box-shadow: 0 10px 14px #9ccbf4;
    border-radius: 5px;
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 2px;
}

.tips {
    width: 100%;
    height: 130px;
    line-height: 120px;
    margin: 90px auto 150px;
    text-align: center;


}

.tips span {
    font-size: 20px;
    color: #8d93ac;
}
</style>